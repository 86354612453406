.section {
    display: block;
    margin-bottom: 40px;
    margin-top: 40px;
}

.grid {
    position: relative;
}

.grid,
.grid_left,
.grid_right {
    display: grid;
    grid-gap: 40px;
}

.grid_center {
    order: -1;
    padding-top: 60px;
}

.title {
    color: var(--teal);
    display: block;
    font-family: Bebas Neue,sans-serif;
    font-size: 54rem;
    font-weight: 700;
    margin: -24px 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 5;
}

.title > span {
    display: block;
    font-size: 16rem;
    margin-top: -10px;
    text-transform: uppercase;
}

.title > span:before {
    background-color: var(--dark-blue);
    content: '';
    display: block;
    height: 5px;
    margin-right: 6px;
    width: 125px;
}

@media screen and (min-width: 861px) {
    .grid {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    .grid_left,
    .grid_right {
        grid-template-rows: min-content;
    }

    .grid_center {
        padding-top: 130px;
    }

    .grid_center h3 {
        font-size: 34rem;
    }

    .title {
        font-size: 74rem;
        height: 10px;
        margin: 10px 0 0;
    }

    .title > span:before {
        display: inline-block;
        height: 10px;
    }
}

@media screen and (min-width: 861px) and (max-width: 1323px) {
    .grid,
    .grid_left,
    .grid_right {
        display: grid;
        grid-gap: 10px;
    }

    .grid {
        row-gap: 20px;
    }

    .grid_left {
        grid-template-columns: minmax(0, 1fr);
    }

    .grid_center {
        grid-column: 1 / -1;
    }

    .grid_right {
        grid-column: span 2;
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

@media screen and (min-width: 1324px) {
    .grid {
        grid-template-columns: 1fr 2.175fr 1fr;
    }

    .grid_center {
        order: initial;
        padding: 0;
    }

    .title {
        position: static;
        display: flex;
        flex-direction: column;
        height: 0;
        justify-content: center;
        padding: 32% 0;
    }
}