.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.wrapper > div {
    min-width: 276px;
}

@media screen and (min-width: 800px) {
    .wrapper {
        flex-direction: row;
    }

    .wrapper > div {
        margin: 0 10px;
    }
}