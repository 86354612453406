.invitation {
    align-items: center;
    height: 100%;
    justify-content: space-around;
}

.invitation h2,
.invitation h3 {
    color: white;
    text-align: center;
}

.invitation h2 {
    font-weight: 100;
}

.invitation h3 {
    font-family: Bebas Neue;
    font-size: 46rem;
}

@media screen and (min-width: 471px) {
    .invitation h3 {
        font-size: 60rem;
    }
}

@media screen and (min-width: 602px) {
    .invitation h2 {
        font-size: 28rem;
    }
}

@media screen and (min-width: 896px) {
    .invitation h2 {
        font-size: 30rem;
    }

    .invitation h3 {
        font-size: 80rem;
    }
}