.h1 {
    font-size: 24rem;
    margin-top: 16px;
    text-align: center;
}

@media screen and (min-width: 801px) {
    .h1 {
        font-size: 36rem;
        margin-top: 36px;
    }
}