.background {
    background-color: var(--gray);
}

.wrapper {
    background-color: var(--gray);
    margin-top: var(--section-spacing);
}

.container_wrapper {
    margin: var(--section-spacing) auto;
    margin-bottom: 0;
    max-width: var(--container-w);
}

.row {
    padding-bottom: 10px;
}

.row:not(:last-of-type) {
    border-bottom: 1px solid var(--input-border-c);
}

.item {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: auto;
    max-width: var(--container-w);
    padding: 10px var(--container-padding) 16px var(--container-padding);
}

.item h2 {
    color: var(--teal);
    flex: 0 0 100%;
    font-size: 18rem;
    font-weight: bold;
    max-width: 1024px;
    text-align: center;
}

.item h2::after {
    content: ':';
}

.item h3 {
    background-color: white;
    border: 1px solid var(--teal);
    display: inline-block;
    font-size: 14rem;
    margin: 0 5px 10px 0;
    padding: 6px 10px;
}

.item h3:hover {
    background: var(--teal);
	color: white;
}

.item a:focus {
    color: var(--dark-blue);
}

@media screen and (min-width: 801px) {
    .item {
        display: block;
    }

    .item h2 {
        text-align: left;
    }
}

@media screen and (min-width: 1151px) {
    .grid {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    .grid .row {
        border-bottom: none;
    }

    .fullrow {
        grid-column: 1 / -1;
    }

    .fullrow:not(:last-of-type) {
        border-bottom: 1px solid var(--input-border-c);
    }
}