.logo {
    height: 80px;
    margin: 6px;
    position: relative;
    width: 80px;
}

@media screen and (max-width: 900px) {
    .logo:nth-child(n+4) {
        display: none;
    }
}

@media screen and (min-width: 400px) {
    .logo {
        height: 100px;
        width: 100px
    }
}

@media screen and (min-width: 510px) {
    .logo {
        height: 150px;
        width: 150px
    }
}

@media screen and (min-width: 901px) and (max-width: 1232px) {
    .logo:nth-child(n+6) {
        display: none;
    }
}